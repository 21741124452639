import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject,Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Injectable({
  providedIn: 'root'
})
export class AppHelperService {
  dayStatus= new BehaviorSubject(false);
  isDayOpen= new BehaviorSubject(false);
  shiftStatus= new BehaviorSubject(false);
  lastSync:any={};
  dayDetails:any=null;
  settings={
    appSettings:null,
    curlingSettings:null,
    purchaseSetting:null
  }
  supplier;
  constructor(private storage:Storage, private platform:Platform,private api:ApiService,private auth:AuthService,
    private navcontroller:NavController, private loadingController: LoadingController) {
    this.platform.ready().then(() => {
      // this.previousStatus=ConnectionStatusEnum.Online;
      // this.storage.get(environment.storagekeys.authtoken).then(data=>{
      //   console.log('token',data);
      //   this.authToken=data;
      //   this.tokenStatus.next(true);
      // });
      // this.storage.get(environment.storagekeys.loginDetails).then(data=>{
      //   this.loginUser=data;
      // });
      this.api.tokenStatusCheck().subscribe((data:any)=>{
        if(data){
          // setTimeout(() => {
            this.appSync(false, true);
          // }, 1000);
        }
      })
    });
  }
  async appSync(manual=false, load=false){
    var loading = await this.loadingController.create({
      message: 'Initializing...',
      spinner: 'bubbles',
      duration:2000
    });
    if(load){
      await loading.present();
    }
    // if(this.api.tokenStatus.value!=null){
    if(this.api.authToken!=null){
      this.api.postWtkn('general/sync').subscribe((d:any)=>{
        if(d.status){
          if(!this.lastSync.dayDetails||this.lastSync.dayDetails!=d.data.dayDetails){
            this.api.postWtkn("dayinfo/getDayInfo")
            .subscribe((day:any)=>{
              if(day.status){
                this.lastSync['dayDetails']=d.data.dayDetails
                if(day.data[0]){
                  this.dayDetails=day.data[0];
                  this.storage.set(environment.storagekeys.dayDetails,day.data[0]);
                  this.isDayOpen.next( !this.dayDetails.isDayClosed)
                }
                this.dayStatus.next(true);
              }
            },
            (error)=>{ 
              // console.log(error);
            })
          }
          if(!this.lastSync.settings||this.lastSync.settings!=d.data.settings){
            if(!this.lastSync.appSettings||this.lastSync.appSettings!=d.data.settingsVersions.appSettings)
              this.api.postWtkn("settings/getsettings",{key:"appSettings"})
              .subscribe((settings:any)=>{
                if(settings.status){
                  this.lastSync['settings']=d.data.settings
                  if(settings.data){
                    this.settings.appSettings=settings.data;
                  }
                }
              },
              (error)=>{ 
                // console.log(error);
              })
            if(!this.lastSync.curlingSettings||this.lastSync.curlingSettings!=d.data.settingsVersions.curlingSettings)
              this.api.postWtkn("settings/getsettings",{key:"curlingSettings"})
              .subscribe((settings:any)=>{
                if(settings.status){
                  this.lastSync['settings']=d.data.settings
                  if(settings.data){
                    this.settings.curlingSettings=settings.data;
                  }
                }
              },
              (error)=>{
                // console.log(error);
              })
            if(d.data.settingsVersions.purchaseSetting)//If purchase setting exists then handle purchase settings
            if(!this.lastSync.purchaseSetting||this.lastSync.purchaseSetting!=d.data.settingsVersions.purchaseSetting)
              this.api.postWtkn("settings/getsettings",{key:"purchaseSetting"})
              .subscribe((settings:any)=>{
                if(settings.status){
                  this.lastSync['settings']=d.data.settings
                  if(settings.data){
                    this.settings.purchaseSetting=settings.data;
                  }
                }
              },
              (error)=>{ 
                // console.log(error);
              })
          }
          this.api.postWtkn("suppliers/getSuppliers",{mode:"getSyncData"})
          .subscribe((supplier:any)=>{
            if(supplier.status){
              this.supplier= supplier.data;
            }
          },
          (error)=>{ 
            // console.log(error);
          })
          this.api.postWtkn('auth/checkisactive').subscribe((data:any)=>{
            // console.log(data);
            if(!data.status){
              this.api.setToken(null);
              this.auth.setLoginStatus(false);
              this.storage.clear();
              this.navcontroller.navigateRoot(['/login']);
            }
          })
        }
      })
    }
    if(!manual)
    setTimeout(() => {
      this.appSync();
    }, 30000);
  }
  getDayStatus(){
    return this.dayStatus.asObservable();
  }
  getDayDetails(){
      return (this.dayDetails==null?null:JSON.parse(JSON.stringify(this.dayDetails)));
  }
  getSettings(key){
    // console.log(this.settings[key]);
    return (this.settings[key]==null?null:JSON.parse(JSON.stringify(this.settings[key])));
  }
  pdfGen(context,options?:{pageSize?:'A3'|'A4'|'A5'|'A6', orientation?:'landscape'|'portrait',
  callMode?:'pdf'|'print',fileName?:String, pageMargins?, fontSize?}){
  let ps=options?(options.pageSize?options.pageSize:'A4'):'A4';
  let po=options?(options.orientation?options.orientation:'portrait'):'portrait';
  let pm=options?(options.pageMargins?options.pageMargins:[10,20,10,20]):[10,20,10,20]; //[left,top,right,bottom]
  let fontSize=options?(options.fontSize?options.fontSize:14):14;
  const documentDefinition = {content: context,
    pageSize: ps,
    pageOrientation: po,
    pageMargins:pm,
    defaultStyle: {
      fontSize: fontSize
    }
  };
  console.log(documentDefinition)
  let filename=options?(options.fileName?options.fileName:'download'):'download';
  let mode=options?(options.callMode?options.callMode:'print'):'print';
  var win = window.open('', '_blank');
  switch (mode) {
    case 'pdf':
      pdfMake.createPdf(documentDefinition).download(filename+".pdf");
      break;
    case 'print':
    default:
      if(this.platform.is('android')){
        pdfMake.createPdf(documentDefinition).open({},win);  
        setTimeout(() => {
          win.close();
        }, 2000);
      }else{
        pdfMake.createPdf(documentDefinition).print({},win);
      }
      break;
  }
}
}
